import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Para redireccionar

const API_URL = process.env.REACT_APP_API_URL + '/login.php'; // Asegúrate de que la URL esté bien configurada

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Hook para redireccionar

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(API_URL, {
                email,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data.status === 'success') {
                // Guardar los datos del usuario en localStorage
                localStorage.setItem('user', JSON.stringify(response.data.user));
                alert('Inicio de sesión exitoso');
                // Redirigir al usuario a la página de productos u otra
                navigate('/productos');
            } else {
                // Mostrar mensaje de error si la respuesta es error
                setError(response.data.message);
            }
        } catch (error) {
            setError('Error al conectar con el servidor');
        }
    };

    const handleLogout = () => {
        // Eliminar datos de usuario del localStorage
        localStorage.removeItem('user');
        alert('Sesión cerrada');
        // Redirigir al usuario a la página de login
        navigate('/login');
    };

    return (
        <div className="login">
            <h2>Iniciar Sesión</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Contraseña:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Iniciar Sesión</button>
            </form>

            {/* Agregar un botón para cerrar sesión si ya está autenticado */}
            {localStorage.getItem('user') && (
                <button onClick={handleLogout}>Cerrar Sesión</button>
            )}
        </div>
    );
};
