import React, { useContext } from 'react';
import logo from '../../images/LogoCandy.png';  // Importa la imagen correctamente
import { Link } from 'react-router-dom';  // Importa Link
import { DataContext } from "../../context/Dataprovider";  // Importa DataContext


export const Header = () => {
  const value = useContext(DataContext);  // Usa useContext
  const [carrito] = value.carrito;  // Usa value.carrito
  const [menu, setMenu] = value.menu;  // Usa value.menu  
  

  const toogleMenu = () => {
    setMenu(!menu);
  }; 


  return (
    <header>
      <Link to='/'>
        <h1 className="logo2"></h1> 
        <img src={logo} alt="logo" width="150"/>  {/* Usa <img> y coloca la imagen en el src */}
      </Link>
      <ul>
        <li><Link to='/'>INICIO</Link></li>
        <li><Link to='/productos'>PRODUCTOS</Link></li>
        <li><Link to='/contacto'>CONTACTO</Link></li>
      </ul>
      <div className="cart" onClick={toogleMenu}>
        <box-icon name='cart'></box-icon>
        <span className='item__total'>{carrito.length}</span>
      </div>
    </header>
  );
};
