import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '200px',
  marginTop: '0px',
};

// Coordenadas aproximadas de la dirección proporcionada (Cerrada Las Cruces 7105, Chihuahua, Chih.)
const center = {
  lat: 28.64647, // Latitud de Chihuahua
  lng: -106.11692, // Longitud de Chihuahua
};

export const Contacto = () => {
  return (
    <div className="contacto-container">
      <h2>Contacto</h2>
      <div className="contact-info">
        <div className="contact-item">
          <h3>Dirección</h3>
          <p>Cerrada Las Cruces 7105, Chihuahua, Chih.</p>
        </div>
        {/* Mapa de Google Maps */}
      <LoadScript googleMapsApiKey="AIzaSyBXqUo2LP2tiLSek5Lf_aN7w956ve_1UuQ">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={20}
        >
          {/* Marcador en la dirección */}
          <Marker position={center} />
        </GoogleMap>
      </LoadScript>
        <div className="contact-item">
          <h3>Teléfono</h3>
          <p>+123 456 7890</p>
        </div>
        <div className="contact-item">
          <h3>Correo Electrónico</h3>
          <p>contacto@impulsoemprendedorcandy.com</p>
        </div>
      </div>

      

      <form className="contact-form">
        <h3>Envíanos un Mensaje</h3>
        <label htmlFor="name">Nombre:</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Correo Electrónico:</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="message">Mensaje:</label>
        <textarea id="message" name="message" rows="4" required></textarea>

        <button type="submit">Enviar</button>
      </form>
    </div>
  );
};
