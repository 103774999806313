import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem('user'); // Verifica si el usuario está autenticado

    if (!isAuthenticated) {
        // Si no está autenticado, redirige a la página de login
        return <Navigate to="/login" />;
    }

    // Si está autenticado, muestra el componente protegido
    return children;
};

export default ProtectedRoute;
