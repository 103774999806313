import React, { useState, useEffect, createContext } from "react";
import axios from 'axios';

export const DataContext = createContext();

// Obtener la URL de la API desde una variable de entorno
const API_URL = process.env.REACT_APP_API_URL + '/productos.php';

export const DataProvider = (props) => {
    const [productos, setProductos] = useState([]);
    const [categorias, setCategorias] = useState([]);  // Estado para almacenar las categorías
    const [menu, setMenu] = useState(false);
    const [carrito, setCarrito] = useState([]);
    const [total, setTotal] = useState(0);

    // Obtener los productos desde la API al cargar la página
    useEffect(() => {
        const fetchProductos = async () => {
            try {
                const response = await axios.get(API_URL);
                setProductos(response.data.items);
            } catch (error) {
                console.error('Error al obtener los datos de la API:', error);
            }
        };

        fetchProductos();
    }, []);

    // Extraer categorías únicas de los productos
    useEffect(() => {
        if (productos && productos.length > 0) {
            const categoriasUnicas = [...new Set(productos.map(producto => producto.category))];
            setCategorias(categoriasUnicas);
        }
    }, [productos]);

    // Función para agregar productos al carrito
    const addCarrito = (id) => {
        const check = carrito.some(item => item.id === id);

        if (!check) {
            const data = productos.find(producto => producto.id === id);
            if (data) {
                data.cantidad = 1;  // Inicializa la cantidad en 1
                const newCarrito = [...carrito, data];
                setCarrito(newCarrito);
                localStorage.setItem('dataCarrito', JSON.stringify(newCarrito));  // Guarda el carrito en localStorage
            }
        } else {
            alert("El producto ya está en el carrito");
        }
    };

    // Recuperar el carrito desde localStorage al cargar la página
    useEffect(() => {
        const dataCarrito = localStorage.getItem('dataCarrito');
        if (dataCarrito) {
            setCarrito(JSON.parse(dataCarrito));
        }
    }, []);

    // Guardar el carrito en localStorage cada vez que se actualiza
    useEffect(() => {
        if (carrito.length > 0) {
            localStorage.setItem('dataCarrito', JSON.stringify(carrito));
        }
    }, [carrito]);

    // Calcular el total del carrito
    useEffect(() => {
        const getTotal = () => {
            const res = carrito.reduce((prev, item) => {
                return prev + (item.price * item.cantidad);
            }, 0);
            setTotal(res);
        };
        getTotal();
    }, [carrito]);

    const value = {
        productos: [productos],
        categorias: [categorias],  // Hacer las categorías disponibles en el contexto
        menu: [menu, setMenu],
        carrito: [carrito, setCarrito],
        addCarrito: addCarrito,
        total: [total, setTotal]
    };

    return (
        <DataContext.Provider value={value}>
            {props.children}
        </DataContext.Provider>
    );
};
