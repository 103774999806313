import { Routes, Route } from 'react-router-dom';
import { Inicio } from './Inicio';
import { ProductosLista } from './Productos/index';
import { Contacto } from './Contacto';
import { Login } from './Login';
import { Signin } from './Signin'; // Importa el nuevo componente
import ProtectedRoute from './ProtectedRoute'; // Importa la ruta protegida

export const Paginas = () => {
  return (
    <section>
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/register" element={<Signin />} /> {/* Agrega la nueva ruta */}
        {/* Ruta protegida para el catálogo de productos */}
        <Route 
          path="/productos" 
          element={
            <ProtectedRoute>
              <ProductosLista />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </section>
  );
};
