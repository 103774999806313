import React, { useState } from 'react';

export const Signin = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        tnumbre: '',
        companyname: '',
        rfc: '',
        password: ''
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const response = await fetch('https://impulsoemprendedorcandy.com/signin.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (result.status === 'success') {
                setSuccess(result.message);
                setError('');
                setFormData({
                    name: '',
                    email: '',
                    tnumbre: '',
                    companyname: '',
                    rfc: '',
                    password: ''
                });
            } else {
                setError(result.message);
                setSuccess('');
            }
        } catch (err) {
            setError('Error connecting to the server.');
        }
    };

    return (
        <div className="signin-container">
            <h2>Registro</h2>
            <form onSubmit={handleSubmit} className="signin-form">
                <input
                    type="text"
                    name="name"
                    placeholder="Nombre"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="signin-input"
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="signin-input"
                />
                <input
                    type="number"
                    name="tnumbre"
                    placeholder="Número de teléfono"
                    value={formData.tnumbre}
                    onChange={handleChange}
                    required
                    className="signin-input"
                />
                <input
                    type="text"
                    name="companyname"
                    placeholder="Nombre de la empresa"
                    value={formData.companyname}
                    onChange={handleChange}
                    required
                    className="signin-input"
                />
                <input
                    type="text"
                    name="rfc"
                    placeholder="RFC"
                    maxLength="13"
                    value={formData.rfc}
                    onChange={handleChange}
                    required
                    className="signin-input"
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    className="signin-input"
                />
                <button type="submit" className="signin-button">Registrarme</button>
            </form>
            {error && <div className="error">{error}</div>}
            {success && <div className="success">{success}</div>}
        </div>
    );
};
