import React, { useContext } from "react";
import { DataContext } from "../../context/Dataprovider";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Importa autotable para tablas
import logo from '../../images/LogoCandy.png'; // Asegúrate de tener el logo en tu carpeta de assets

export const Carrito = () => {
  const value = React.useContext(DataContext);
  const [menu, setMenu] = value.menu;
  const [carrito, setCarrito] = value.carrito;
  const [total] = value.total;

  const tooglefalse = () => {
    setMenu(false);
  };

  const show1 = menu ? "carritos show" : "carrito";
  const show2 = menu ? "carrito show" : "carrito";

  const resta = (id) => {
    carrito.forEach((item) => {
      if (item.id === id) {
        item.cantidad === 1 ? (item.cantidad = 1) : (item.cantidad -= 1);
      }
      setCarrito([...carrito]);
    });
  };

  const suma = (id) => {
    carrito.forEach((item) => {
      if (item.id === id) {
        item.cantidad += 1;
      }
      setCarrito([...carrito]);
    });
  };

  const removeProducto = (id) => {
    if (window.confirm("¿Quieres eliminar este producto?")) {
      carrito.forEach((item, index) => {
        if (item.id === id) {
          item.cantidad = 1;
          carrito.splice(index, 1);
        }
      });
      setCarrito([...carrito]);
    }
  };

  const generarYEnviarPDF = () => {
    const doc = new jsPDF();
    const doc2 = new jsPDF();

    // Agregar logo en la esquina superior izquierda
    doc.addImage(logo, 'PNG', 10, 10, 50, 20);
    doc2.addImage(logo, 'PNG', 10, 10, 50, 20);

    // Título del documento
    doc.text("Comprobante de Cotización", 70, 30);
    doc2.text("Comprobante de Cotización con Código Interno", 70, 30);

    // Información del cliente
    doc.text("Cliente: Nombre del Cliente", 20, 50);
    doc.text("Fecha: " + new Date().toLocaleDateString(), 20, 60);
    doc2.text("Cliente: Nombre del Cliente", 20, 50);
    doc2.text("Fecha: " + new Date().toLocaleDateString(), 20, 60);

    // Tabla de productos (sin código interno)
    const columns = ["Producto", "Precio", "Cantidad", "Total"];
    const rows = carrito.map((item) => {
      const price = parseFloat(item.price);
      return [
        item.title,
        `$${price.toFixed(2)}`,
        item.cantidad,
        `$${(price * item.cantidad).toFixed(2)}`,
      ];
    });

    doc.autoTable({
      startY: 70,
      head: [columns],
      body: rows,
    });

    // Tabla de productos con el campo extra (para el segundo PDF)
    const columnsExtra = ["Producto", "Código Interno", "Precio", "Cantidad", "Total"];
    const rowsExtra = carrito.map((item) => {
      const price = parseFloat(item.price);
      return [
        item.title,
        item.internalcode || 'N/A', // Mostrar 'N/A' si internalcode no está disponible
        `$${price.toFixed(2)}`,
        item.cantidad,
        `$${(price * item.cantidad).toFixed(2)}`,
      ];
    });

    doc2.autoTable({
      startY: 70,
      head: [columnsExtra],
      body: rowsExtra,
    });

    // Total final
    doc.text(`Total: $${total.toFixed(2)}`, 20, doc.lastAutoTable.finalY + 10);
    doc2.text(`Total: $${total.toFixed(2)}`, 20, doc2.lastAutoTable.finalY + 10);

    // Descargar el primer PDF
    doc.save("comprobante_cotizacion.pdf");

    // Convertir el segundo PDF a Blob y enviarlo por correo
    const pdfBlob = doc2.output('blob');
    enviarCorreoConPDF(pdfBlob, "cotizacion_con_codigo.pdf");
  };

  const enviarCorreoConPDF = (pdfBlob, nombreArchivo = "cotizacion.pdf") => {
    const formData = new FormData();
    formData.append("pdf", pdfBlob, nombreArchivo);

    fetch("https://impulsoemprendedorcandy.com/mail.php", { // Cambia esta URL a la de tu script PHP
        method: "POST",
        body: formData,
        headers: {
            'From': 'emprendedores@candymart.com.mx', // Cambia a tu correo
            'To': 'emprendedores@candymart.com.mx'   // Cambia a tu correo
        }
    })
    .then(response => response.json())
    .then(data => {
        if (data.success) {
            alert("Correo enviado con éxito");
        } else {
            alert("Error al enviar el correo");
        }
    })
    .catch(error => {
        console.error("Error al enviar el correo:", error);
        alert("No se pudo enviar el correo. Inténtalo más tarde.");
    });
  };

  return (
    <div className={show1}>
      <div className={show2}>
        <div className="carrito__close" onClick={tooglefalse}>
          <box-icon name="x"></box-icon>
        </div>
        <h2>Su carrito</h2>

        <div className="carrito__center">
          {carrito.length === 0 ? (
            <h2 style={{ textAlign: "center", fontSize: "2rem" }}>
              Carrito vacío
            </h2>
          ) : (
            <>
              {carrito.map((producto) => (
                <div className="carrito__item" key={producto.id}>
                  <img src={producto.image.default} alt="" />
                  <div>
                    <h3>{producto.title}</h3>
                    <p className="price">${producto.price}</p>
                  </div>
                  <div>
                    <box-icon
                      onClick={() => suma(producto.id)}
                      name="up-arrow"
                      type="solid"
                    />
                    <p className="cantidad">{producto.cantidad}</p>
                    <box-icon
                      onClick={() => resta(producto.id)}
                      name="down-arrow"
                      type="solid"
                    />
                  </div>
                  <div
                    onClick={() => removeProducto(producto.id)}
                    className="remove__item"
                  >
                    <box-icon name="trash" />
                  </div>
                </div>
              ))}
            </>
          )}
        </div>

        <div className="carrito__footer">
          <h3>Total: ${total}</h3>
          <button className="btn" onClick={generarYEnviarPDF}>Generar PDF</button>
        </div>
      </div>
    </div>
  );
};
